"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = formatInTimeZone;

var _index = _interopRequireDefault(require("date-fns/_lib/cloneObject/index.js"));

var _index2 = _interopRequireDefault(require("../format/index.js"));

var _index3 = _interopRequireDefault(require("../utcToZonedTime/index.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * @name formatInTimeZone
 * @category Time Zone Helpers
 * @summary Gets the offset in milliseconds between the time zone and Universal Coordinated Time (UTC)
 *
 * @param {Date|String|Number} date - the date representing the local time / real UTC time
 * @param {String} timeZone - the time zone this date should be formatted for; can be an offset or IANA time zone
 * @param {String} formatStr - the string of tokens
 * @param {OptionsWithTZ} [options] - the object with options. See [Options]{@link https://date-fns.org/docs/Options}
 * @param {0|1|2} [options.additionalDigits=2] - passed to `toDate`. See [toDate]{@link
 *   https://date-fns.org/docs/toDate}
 * @param {0|1|2|3|4|5|6} [options.weekStartsOn=0] - the index of the first day of the week (0 - Sunday)
 * @param {Number} [options.firstWeekContainsDate=1] - the day of January, which is
 * @param {Locale} [options.locale=defaultLocale] - the locale object. See
 *   [Locale]{@link https://date-fns.org/docs/Locale}
 * @param {Boolean} [options.awareOfUnicodeTokens=false] - if true, allows usage of Unicode tokens causes confusion:
 *   - Some of the day of year tokens (`D`, `DD`) that are confused with the day of month tokens (`d`, `dd`).
 *   - Some of the local week-numbering year tokens (`YY`, `YYYY`) that are confused with the calendar year tokens
 *   (`yy`, `yyyy`). See: https://git.io/fxCyr
 * @param {String} [options.timeZone=''] - used to specify the IANA time zone offset of a date String.
 * @returns {String} the formatted date string
 */
function formatInTimeZone(date, timeZone, formatStr, options) {
  var extendedOptions = (0, _index.default)(options);
  extendedOptions.timeZone = timeZone;
  return (0, _index2.default)((0, _index3.default)(date, timeZone), formatStr, extendedOptions);
}

module.exports = exports.default;